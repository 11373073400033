import React, { useState } from "react";
import PlusIcon from "../../../Assets/icons/plusIcon-blue.svg";
import OpenChev from "../../../Assets/icons/openChev-blue.svg";
import NoteIcon from "../../../Assets/icons/noteIcon.svg";
import Data from "../../../JSON/data.json";
import { Formik } from "formik";
import {
  useUpdateUserDetailsMutation,
  useUpdateUserMutation,
} from "../../../Store/slice/AuthSlice";
import { toast } from "react-toastify";
import Loader from "../../buttons/Loader";
import { skillsSchema } from "../../validation/FormikValidation";

const InnerStep3 = ({
  openStep,
  setOpenStep,
  setCurrentStep,
  userDetail,
  setUserDetails,
  setSubStep,
}) => {
  const sample_text = Data?.Sample_skills_text;
  const [open, setOpen] = useState(false);
  const [updateSkills, { error, isLoading }] = useUpdateUserDetailsMutation();
  const [
    registerUserDetail,
    { error: isstepsError, isLoading: isstepsLoading },
  ] = useUpdateUserMutation();

  if (error || isstepsError) {
    if (error?.originalStatus || isstepsError?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(
        error?.data?.message ||
          isstepsError?.data?.message ||
          "Something went wrong!"
      );
    }
  }

  return (
    <Formik
      initialValues={{
        skills: userDetail?.details?.skills || [],
      }}
      validationSchema={skillsSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        const data = {
          skills: values?.skills?.filter((skill) => skill !== ""),
        };
        const response = await updateSkills(data);
        if (response?.error) {
          toast.error(
            response?.error?.data?.errors[0]?.detail || "何か問題が発生しました"
          );
        }
        if (response?.data?.success === true) {
          const stepResponse = await registerUserDetail({ steps_completed: "skills" });

          if (stepResponse?.error) {
            toast.success(response?.error?.data?.errors[0]);
          }

          if (stepResponse?.data?.success === true) {
            setUserDetails({ details: response?.data?.data?.detail });
            setOpenStep(4);
            setSubStep(4);
            setCurrentStep(2);
            toast.success(
              response?.data?.message || "スキルが正常に更新されました。"
            );
            resetForm();
          }
        }
      }}
    >
      {({ handleBlur, values, handleSubmit, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit}>
          <div className="overflow-y-auto mt-[24px]">
            <div>
              {values?.skills?.map?.((items, index) => {
                return (
                  <div
                    key={index}
                    className="bg-[#F6F6F6] px-[12px] py-[16px] w-full max-h-[88px] mb-[16px]"
                  >
                    <p className="flex items-center gap-[9px]">
                      <img src={NoteIcon} alt="NoteIcon" />
                      <input
                        name={`skills.${index}`}
                        type="text"
                        placeholder=""
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(`skills.${index}`, e.target.value);
                        }}
                        value={items}
                        className="border text-[14px] font-normal w-full leading-[22px] text-[#212121] px-[16px] py-[8px] rounded-[4px] outline-none"
                      />
                    </p>
                    <div className="mt-[8px]">
                      <p
                        className="text-[#0956FF] text-xs font-normal leading cursor-pointer"
                        onClick={() => {
                          const remove = values?.skills.filter(
                            (ele) => ele !== items
                          );
                          setFieldValue("skills", remove);
                        }}
                      >
                        このスキルを削除する
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center ">
              <button
                type="button"
                className="flex items-center justify-center border border-[#212121] py-2 md:py-[13px] px-4 md:px-[68px] h-[48px] rounded-[4px] gap-[4px] md:w-full"
                onClick={() =>
                  setFieldValue(`skills.${[values?.skills?.length]}`, "")
                }
              >
                <img
                  src={PlusIcon}
                  alt="PlusIcon"
                  className="h-[12px] w-[12px]"
                />
                <p className="text-[#212121] text-[14px] font-bold leading-[22px]">
                  スキルを追加する
                </p>
              </button>
            </div>
            <div className="mt-[24px]">
              <button
                type="button"
                className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <img
                    src={OpenChev}
                    alt="cheav"
                    className="transform rotate-[180deg]"
                  />
                ) : (
                  <img src={OpenChev} alt="cheav" />
                )}
                <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                  サンプル記入例
                </p>
              </button>
              {open && (
                <div className="text-[14px] font-normal leading-[22px]">
                  {sample_text?.map((text, index) => {
                    return (
                      <p
                        key={index}
                        className="flex items-center gap-[9px] mt-2"
                      >
                        <input
                          type="text"
                          disabled
                          value={text}
                          className="text-[14px] font-normal w-full leading-[22px] bg-[#E6EEFF] px-[16px] py-[8px] rounded-[4px] outline-none"
                        />
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="mt-[40px]">
            <div className="flex gap-[10px] items-center">
              <button
                type="button"
                className="py-[12px] px-[24px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                onClick={() => {
                  setCurrentStep(2);
                  setOpenStep(2);
                }}
              >
                キャンセル
              </button>
              <button
                type="submit"
                disabled={
                  Object.keys(errors)?.length || isLoading || isstepsLoading
                }
                className={`py-[12px] px-[24px] ${
                  Object.keys(errors)?.length || isLoading || isstepsLoading
                    ? "bg-neutral-500"
                    : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                } rounded-[4px] text-xs font-bold text-[#fff]`}
              >
                {isLoading || isstepsLoading ? (
                  <div className="flex justify-center">
                    <Loader />
                  </div>
                ) : (
                  "保存する"
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default InnerStep3;
