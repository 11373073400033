import { useContext, useEffect, useState } from "react";
import ResumeImg from "../../Assets/Images/resume-image-1.svg";
import RegistrationSteps from "../../Components/registrationSteps/RegistrationSteps";
import { useLocation } from "react-router-dom";
import { ContextData } from "../../Store/Context";

const RegistrationProcess = () => {
  const { userProfile, setUserProfile, userDetail, setUserDetails } =
    useContext(ContextData);
  const location = useLocation();
  const [showSteps, setShowSteps] = useState(false);
  const [step, setStep] = useState(location?.state?.step);
  const [subStep, setSubStep] = useState(location?.state?.innerStep);

  useEffect(() => {
    // getUserData()
    if (location?.state?.step > 0) {
      setShowSteps(true);
    }
  }, []);

  return (
    <div className="min-h-[calc(100vh-316px)]">
      <div
        className={`bg-white mx-8 sm:mx-auto my-[64px] px-3 py-5 lg:py-10 lg:px-20 h-fit w-fit flex flex-col items-center border ${
          showSteps ? "hidden" : "block"
        }`}
      >
        <div className="rounded-full bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px]">
          <button className="text-xs md:text-base py-2 px-4 text-[#0956FF] h-full w-full bg-white md:py-3 md:px-6 rounded-full font-bold ">
            最短５分！
          </button>
        </div>
        <h1 className="text-[18px] md:text-[22px] lg:text-[24px] font-bold text-center mt-4 px-3">
          まずはあなたの職務経歴書を作成してみましょう
        </h1>
        <button
          className="py-3 px-[98px] lg:px-[121px] bg-gradient-to-r from-[#0956FF] to-[#5389FF] rounded-[4px] my-10 font-bold text-white"
          onClick={() => setShowSteps(true)}
        >
          はじめる
        </button>
        <img
          src={ResumeImg}
          alt=""
          className="h-[85%] w-[85%] lg:h-full lg:w-full"
        />
      </div>

      {/************************************** Steps*********************************************** */}

      {showSteps && (
        <RegistrationSteps
          userstep={step}
          setUserStep={setStep}
          subStep={subStep}
          setSubStep={setSubStep}
          userProfile={userProfile}
          setUserProfile={setUserProfile}
          userDetail={userDetail}
          setUserDetails={setUserDetails}
        />
      )}
    </div>
  );
};

export default RegistrationProcess;
