import { useEffect, useState } from "react";
import AI from "../../../Assets/icons/ai-blue.svg";
import OpenChev from "../../../Assets/icons/openChev-blue.svg";
import pinIcon from "../../../Assets/icons/pinicon.svg";
import PlusIcon from "../../../Assets/icons/plusIcon-blue.svg";

import Data from "../../../JSON/data.json";
import { Formik } from "formik";
import { scrollToErrors } from "../../comman/ScrollToError";
import { addBioProjectHistory } from "../../validation/FormikValidation";
import { toast } from "react-toastify";
import { useAddProjectHistoryMutation } from "../../../Store/slice/ProjectSlice";
import { useUpdateUserMutation } from "../../../Store/slice/AuthSlice";
import Loader from "../../buttons/Loader";

const InnerStep5 = ({
  openStep,
  setOpenAIModal,
  setOpenStep,
  setCurrentStep,
  userDetail,
  setUserDetails,
  setSelectedType,
  setUserStep,
  setSubStep,
}) => {
  const speciality = Data?.specialty;
  const [open, setOpen] = useState(false);
  const CurrentYear = new Date().getFullYear();
  const CurrentMonth = new Date().getMonth() + 1;
  const [selectedItems, setSelectedItems] = useState([]);
  const [aiPopup, setAiPopup] = useState(true);
  const [aiPopupachievements, setAiPopupachievements] = useState(true);

  const [addProjectHistory, { error, isLoading }] =
    useAddProjectHistoryMutation();
  const [
    registerUserDetail,
    { error: isstepsError, isLoading: isstepsLoading },
  ] = useUpdateUserMutation();
  const project =
    userDetail?.details?.project_histories?.[
      userDetail?.details?.project_histories?.length - 1
    ];

  if (error || isstepsError) {
    if (error?.originalStatus || isstepsError?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(
        error?.data?.message ||
          isstepsError?.data?.message ||
          "Something went wrong!"
      );
    }
  }

  const startdate = new Date(project?.start_date);
  const startYear = startdate?.getFullYear();
  const startMonth = startdate?.getMonth() + 1;

  const enddate = new Date(project?.end_date);
  const endYear = enddate?.getFullYear();
  const endMonth = enddate?.getMonth() + 1;

  const handleClick = (category, sm) => {
    const selectedItem = { category, sm };
    const isSelected = selectedItems.some(
      (item) => item.category === category && item.sm === sm
    );
    if (isSelected) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (item) => !(item.category === category && item.sm === sm)
        )
      );
    } else {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        selectedItem,
      ]);
    }
  };

  useEffect(() => {
    if (project) {
      const skillData = project?.related_skills?.map((skill) => {
        const key = skill.split(":")[0].replace("{", "").trim();
        const value = skill
          .split(":")[1]
          .trim()
          .replace("}", "")
          .replace("[", "")
          .replace("]", "")
          .trim()
          .split(",");
        return { [key]: value };
      });
      const result = skillData?.reduce((acc, curr) => {
        const [key] = Object.keys(curr);
        const values = curr[key];
        return [
          ...acc,
          ...values.map((value) => {
            return { category: key, sm: value };
          }),
        ];
      }, []);
      setSelectedItems(result);
    }
  }, [project]);

  return (
    <Formik
      initialValues={{
        currently_affiliated: project?.currently_affiliated || false,
        name: project?.project_name || "",
        client_name: project?.company || "",
        role: project?.role || "",
        affiliation: project?.affiliation || "",
        start_year: startYear || "2010",
        start_month: startMonth || new Date().getMonth() + 1,
        end_year: endYear || new Date().getFullYear(),
        end_month: endMonth || new Date().getMonth() + 1,
        overview: project?.project_overview || "",
        achievements: project?.achievements_initiatives || "",
        responsibilities: project?.responsibilities || [],
        specialities: {},
      }}
      innerRef={(ref) => {
        if (ref?.errors && ref?.isSubmitting === true) {
          scrollToErrors(ref?.errors);
        }
      }}
      validationSchema={addBioProjectHistory}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm, setErrors }) => {
        if (values.start_year === values.end_year) {
          if (values.start_month > values.end_month) {
            setErrors({ end_month: "end month is not valid" });
            return;
          }
        }

        const start = new Date(
          values.start_year.toString() +
            "-" +
            values.start_month.toString() +
            "-" +
            "01"
        )
          .toLocaleDateString("ja-JP")
          .replaceAll("/", "-");
        const end = new Date(
          values.end_year.toString() +
            "-" +
            values.end_month.toString() +
            "-" +
            "30"
        )
          .toLocaleDateString("ja-JP")
          .replaceAll("/", "-");

        // Get Selected Values From Related Skills
        function selectedCategory() {
          var categories = [];
          function unique(value, index, array) {
            return array.indexOf(value) === index;
          }

          var newCategories = selectedItems
            .map((item) => item.category)
            .filter(unique);

          newCategories.forEach((categorie) => {
            let val = [];
            selectedItems.forEach((item) => {
              if (categorie === item.category) {
                val.push(item.sm);
              }
            });
            categories.push({ [categorie]: val });
          });

          return categories;
        }
        // Set values into data
        const categories = selectedCategory();
        const data = {
          project_history: {
            project_name: values.name,
            company: values.client_name,
            role: values.role,
            affiliation: values.affiliation,
            project_overview: values.overview,
            responsibilities: values?.responsibilities?.filter(
              (item) => item !== ""
            ),
            achievements_initiatives: values.achievements,
            start_date: start,
            end_date: end,
            related_skills: categories.map((obj) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              return `{ ${key}: [${value.map((val) => `${val}`)}] }`;
            }),
          },
        };

        const response = await addProjectHistory(data);
        if (response?.error) {
          toast.error(
            response?.error?.data?.errors[0]?.detail || "何か問題が発生しました"
          );
        }

        if (response?.data?.success === true) {
          const stepResponse = await registerUserDetail({
            steps_completed: "project_history",
          });

          if (stepResponse?.error) {
            toast.success(response?.error?.data?.errors[0]);
          }

          if (stepResponse?.data?.success === true) {
            setUserDetails({
              ...userDetail,
              details: {
                ...userDetail?.details,
                project_histories: [
                  ...userDetail?.details?.project_histories,
                  { ...response?.data?.data },
                ],
              },
            });
            setOpenStep(5);
            setSubStep(6);
            setUserStep(3);
            toast.success(
              response?.data?.message ||
                "プロジェクト履歴が正常に追加されました。"
            );
          }
        }
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <h1
            className="text-[16px] leading-[24px] font-bold text-[#878787] mb-[16px] mt-[40px]"
          >
            {Data?.project_history_title}
          </h1>
          <div className="mt-[16px]">
            <div className="sm:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
              <div className="w-[115px]">
                <label htmlFor="name">プロジェクト名</label>
              </div>
              <input
                name="name"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.name}
                placeholder="プロジェクト名"
                className="w-full mt-1 flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
              />
            </div>
            {errors.name && touched.name && (
              <p className="text-red-600 text-[12px] ml-[128px]">
                *{errors.name}
              </p>
            )}
          </div>
          <div className="mt-[16px]">
            <div className="sm:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
              <div className="w-[115px]">
                <label htmlFor="client_name">クライアント業種</label>
              </div>
              <input
                name="client_name"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.client_name}
                placeholder="製造業"
                className="w-full mt-1 flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
              />
            </div>
            {errors.client_name && touched.client_name && (
              <p className="text-red-600 text-[12px] ml-[128px]">
                *{errors.client_name}
              </p>
            )}
          </div>
          <div className="mt-[16px]">
            <div className="sm:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
              <div className="w-[115px]">
                <label htmlFor="role">役割</label>
              </div>
              <input
                name="role"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.role}
                placeholder="プロジェクトマネージャー / アシスタント / PMO"
                className="w-full mt-1 flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
              />
            </div>
            {errors.role && touched.role && (
              <p className="text-red-600 text-[12px] ml-[128px]">
                *{errors.role}
              </p>
            )}
          </div>
          <div className="mt-[16px]">
            <div className="sm:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
              <div className="w-[115px]">
                <label htmlFor="affiliation">所属</label>
              </div>
              <input
                name="affiliation"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.affiliation}
                placeholder="サンプル株式会社 / フリーランス"
                className="w-full mt-1 flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
              />
            </div>
            {errors.affiliation && touched.affiliation && (
              <p className="text-red-600 text-[12px] ml-[128px]">
                *{errors.affiliation}
              </p>
            )}
          </div>
          <div className="mt-[16px]">
            <div className="sm:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
              <div className="w-[115px]">
                <label htmlFor="affiliation_period ">稼働期間</label>
              </div>
              <div className="font-normal">
                <div>
                  <div>
                    <label className="cursor-pointer text-xs mt-[10px] w-fit gap-3 text-center flex justify-start items-end registration">
                      <input
                        name="currently_affiliated"
                        type="checkbox"
                        checked={values?.currently_affiliated}
                        disabled={!values?.start_year || !values?.start_month}
                        className="h-[18px] w-[18px] cursor-pointer"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "currently_affiliated",
                            e.target.checked
                          );
                          if (e.target.checked === true) {
                            setFieldValue("end_year", CurrentYear);
                            setFieldValue("end_month", CurrentMonth);
                          }
                          if (e.target.checked === false) {
                            setFieldValue("end_year", values?.end_year);
                            setFieldValue("end_month", values?.end_month);
                          }
                        }}
                      />
                      現在も稼働している
                    </label>
                  </div>
                  <div className="sm:flex items-center mt-3">
                    <div className="flex justify-start items-center">
                      <input
                        name="start_year"
                        type="number"
                        min={1900}
                        max={new Date().getFullYear()}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.start_year}
                        className="w-[65px] h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px]"
                      />
                      <p className="px-[8px]">年</p>
                      <input
                        name="start_month"
                        type="number"
                        min={1}
                        max={12}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.start_month}
                        className="w-[50px] h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px]"
                      />
                      <div className="flex">
                        <p className="px-[8px]">月</p>
                        <p className="px-[8px]">〜</p>
                      </div>
                    </div>
                    <div className="flex justify-start items-center mt-3 sm:mt-0">
                      <input
                        name="end_year"
                        type="number"
                        min={1900}
                        max={new Date().getFullYear()}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={values?.currently_affiliated}
                        value={values.end_year}
                        className={`w-[65px] h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px] ${
                          values?.currently_affiliated
                            ? "bg-gray-300"
                            : "bg-white"
                        }`}
                      />
                      <p className="px-[8px]">年</p>
                      <input
                        name="end_month"
                        type="number"
                        min={1}
                        max={
                          values?.end_year === new Date().getFullYear()
                            ? new Date().getMonth() + 1
                            : 12
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.end_month}
                        disabled={values?.currently_affiliated}
                        className={`w-[50px] h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px] ${
                          values?.currently_affiliated
                            ? "bg-gray-300"
                            : "bg-white"
                        }`}
                      />
                      <p className="px-[8px]">月</p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-self-start">
                  <div>
                    {errors.start_year && touched.start_year && (
                      <p className="text-red-600 text-[12px] ml-[128px]">
                        *{errors.start_year}
                      </p>
                    )}
                    {errors.start_month && touched.start_month && (
                      <p className="text-red-600 text-[12px] ml-[128px]">
                        *{errors.start_month}
                      </p>
                    )}
                  </div>
                  <div>
                    {errors.end_year && touched.end_year && (
                      <p className="text-red-600 text-[12px] ml-[135px]">
                        *{errors.end_year}
                      </p>
                    )}
                    {errors.end_month && touched.end_month && (
                      <p className="text-red-600 text-[12px] ml-[135px]">
                        *{errors.end_month}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-7 relative">
            <h6 className="text-[14px] leading-[22px] font-normal text-[#212121] mb-[8px] mt-[16px]">
              プロジェクト概要
            </h6>
            <div>
              <div className="flex justify-end items-center my-2 ">
                <div
                  className={`bg-[#0956FF] p-4 text-white text-xs rounded-md absolute -top-14 -right-4 ${
                    aiPopup ? "block" : "hidden"
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setAiPopup(false);
                    }}
                    className="absolute top-[2px] right-1"
                  >
                    &#x2715;
                  </button>
                  <span className="font-semibold">
                    文章を簡単に生成できます！
                  </span>
                  <span className="absolute -bottom-2 left-1/2 border-l-[10px] border-r-[10px] border-t-[10px] border-x-transparent border-t-[#0956FF]"></span>
                </div>
                <div className="bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px] rounded">
                  <button
                    type="button"
                    className="flex items-center text-[#0956FF] text-[8px] md:text-[10px] xl:text-[12px] font-bold bg-white py-2 md:py-[12px] px-3 md:px-[16px] rounded"
                    onClick={() => {
                      setOpenAIModal(true);
                      setSelectedType(1);
                    }}
                  >
                    <span>
                      <img src={AI} alt="FileSvg" />
                    </span>
                    &nbsp;
                    <span>{Data?.ai_text}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-[8px] px-[16px] border border-[#CED0D8]">
            <textarea
              name="overview"
              value={values?.overview}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="プロジェクト概要"
              className="text-[14px] font-normal leading-[22px] w-full h-[82px] resize-none outline-none"
            ></textarea>
            {errors.overview && touched.overview && (
              <p className="text-red-600 text-[12px] ">*{errors.overview}</p>
            )}
          </div>
          <div className="my-[24px]">
            <button
              type="button"
              className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
              onClick={() => setOpen(!open)}
            >
              {!open ? (
                <img
                  src={OpenChev}
                  alt="cheav"
                  className="transform rotate-[180deg]"
                />
              ) : (
                <img src={OpenChev} alt="cheav" />
              )}
              <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                サンプル記入例
              </p>
            </button>
            {open && (
              <div className=" bg-[#E6EEFF] h-[auto] pt-[8px] pr-[16px] pb-[8px] pl-[16px] mt-[4px]">
                <div className="text-[14px] font-normal leading-[22px] h-[82px] text-justify">
                  事業成長を目的としたM＆Aによる投資先選定に際する、投資テーマ・投資領域の評価及び、ロング、ショートリストの作成支援
                </div>
              </div>
            )}
          </div>
          <h1 className="text-[16px] leading-[24px] font-bold text-[#878787] mb-[16px] mt-[16px]">
            担当業務
          </h1>
          <div className="">
            {values?.responsibilities?.map?.((items, index) => {
              return (
                <div className="bg-[#F6F6F6] px-[12px] py-[16px] w-full max-h-[88px] mb-[16px]">
                  <p className="flex items-center gap-[9px]">
                    <img src={pinIcon} alt="pinIcon" />
                    <input
                      name={`responsibilities.${index}`}
                      type="text"
                      placeholder=""
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue(
                          `responsibilities.${index}`,
                          e.target.value
                        );
                      }}
                      value={items}
                      className="border text-[14px] font-normal w-full leading-[22px] text-[#212121] px-[16px] py-[8px] rounded-[4px]"
                    />
                  </p>
                  <div className="mt-[8px]">
                    <p
                      className="text-[#FF6F00] text-xs font-normal leading cursor-pointer"
                      onClick={() => {
                        const remove = values?.responsibilities.filter(
                          (ele) => ele !== items
                        );
                        setFieldValue("responsibilities", remove);
                      }}
                    >
                      このスキルを削除する
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center w-full">
            <button
              type="button"
              className="flex items-center justify-center border border-[#212121] py-[13px] px-[68px] h-[48px] rounded-[4px] gap-[4px] w-full"
              onClick={() =>
                setFieldValue(
                  `responsibilities.${[values?.responsibilities?.length]}`,
                  ""
                )
              }
            >
              <img
                src={PlusIcon}
                alt="PlusIcon"
                className="h-[12px] w-[12px]"
              />
              <p className="text-[#212121] text-[14px] font-bold leading-[22px]">
                担当業務を追加する
              </p>
            </button>
          </div>
          <div className="flex justify-between items-center mt-7 relative">
            <h6 className="text-[14px] leading-[22px] font-normal text-[#212121] mb-[8px] mt-[16px]">
              実績/取り組み
            </h6>
            <div>
              <div className="flex justify-end items-center my-2">
                <div
                  className={`bg-[#0956FF] p-4 text-white text-xs rounded-md absolute -top-14 -right-4 ${
                    aiPopupachievements ? "block" : "hidden"
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setAiPopupachievements(false);
                    }}
                    className="absolute top-[2px] right-1"
                  >
                    &#x2715;
                  </button>
                  <span className="font-semibold">
                    文章を簡単に生成できます！
                  </span>
                  <span className="absolute -bottom-2 left-1/2 border-l-[10px] border-r-[10px] border-t-[10px] border-x-transparent border-t-[#0956FF]"></span>
                </div>
                <div className="bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px] rounded">
                  <button
                    type="button"
                    className="flex items-center text-[#0956FF] text-[8px] md:text-[10px] xl:text-[12px] font-bold bg-white py-2 md:py-[12px] px-3 rounded"
                    onClick={() => {
                      setOpenAIModal(true);
                      setSelectedType(2);
                    }}
                  >
                    <span>
                      <img src={AI} alt="FileSvg" />
                    </span>
                    &nbsp;
                    <span>{Data?.ai_text}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-[8px] px-[16px] border border-[#CED0D8]">
            <textarea
              name="achievements"
              className="text-[14px] font-normal leading-[22px] w-full h-[174px] resize-none outline-none"
              value={values?.achievements}
              placeholder="実績/取り組み"
              onBlur={handleBlur}
              onChange={handleChange}
            ></textarea>
            {errors.achievements && touched.achievements && (
              <p className="text-red-600 text-[12px] ">
                *{errors.achievements}
              </p>
            )}
          </div>
          <div className="my-[24px]">
            <button
              type="button"
              className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
              onClick={() => setOpen(!open)}
            >
              {!open ? (
                <img
                  src={OpenChev}
                  alt="cheav"
                  className="transform rotate-[180deg]"
                />
              ) : (
                <img src={OpenChev} alt="cheav" />
              )}
              <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                サンプル記入例
              </p>
            </button>
            {open && (
              <div className=" bg-[#E6EEFF] h-[auto] pt-[8px] pr-[16px] pb-[8px] pl-[16px] mt-[4px]">
                <div className="text-[14px] font-normal leading-[22px] text-justify sm:h-[174px]">
                  グローバル市場におけるサプライチェーンの動向分析を実施し、業界内外のベストプラクティスを綿密に調査。ターゲットとなる市場セグメントおよび業界を特定し、クライアントのビジネス戦略に沿ったM&Aの方向性を策定。業界の専門家やアドバイザーとの連携を通じ、広範なネットワークから有望な企業群を抽出し、ロングリストを作成した。成果としてクライアントのM＆Aの意思決定に貢献
                </div>
              </div>
            )}
          </div>
          <h1 className="text-[16px] my-[24px] leading-[24px] font-bold text-[#878787] mb-[16px]">
            関連スキル
          </h1>
          {speciality?.map((items) => {
            return (
              <div key={items?.id} className="mb-[24px]">
                <h1 className="text-[16px] leading-[24px] font-bold text-[#878787] mb-[8px]">
                  {items?.category}
                </h1>
                <div className="flex gap-[8px] items-center flex-wrap">
                  {items?.subcategories?.map((category) => {
                    const isSelected = selectedItems.some(
                      (item) =>
                        item.category === items?.category &&
                        item.sm === category?.sm
                    );
                    return (
                      <p
                        onClick={() =>
                          handleClick(items?.category, category?.sm)
                        }
                        key={category?.id}
                        className={`text-[14px] cursor-pointer font-normal leading-[22px] py-[6px] rounded-full px-[16px]  ${
                          isSelected
                            ? `text-[#0956FF] bg-[#E6EEFF] border border-[#0956FF]`
                            : "border-0 bg-[#F6F6F6] text-[#878787]"
                        } w-[fit-content] rounded-full`}
                      >
                        {category?.sm}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="mt-[40px]">
            <div className="flex gap-[10px] items-center">
              <button
                type="button"
                className="py-[12px] px-[24px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                onClick={() => {
                  setCurrentStep(2);
                  setOpenStep(4);
                }}
              >
                キャンセル
              </button>
              <button
                type="submit"
                disabled={
                  Object.keys(errors)?.length || isLoading || isstepsLoading
                }
                className={`py-[12px] px-[24px] ${
                  isLoading || isstepsLoading
                    ? "bg-neutral-500"
                    : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                } rounded-[4px] text-xs font-bold text-[#fff]`}
              >
                {isLoading || isstepsLoading ? (
                  <div className="flex justify-center">
                    <Loader />
                  </div>
                ) : (
                  "保存する"
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default InnerStep5;
