import React from "react";
import Data from "../../JSON/data.json";
import CloseModalButton from "../buttons/CloseModalButton";
// import Loader from "../buttons/Loader";
// import Arrow from "../../Assets/icons/downArrow.svg";
import ArrowBlue from "../../Assets/icons/downArrow-blue.svg";
import { useLocation } from "react-router-dom";

const AIDraftSuccess = ({
  refModal,
  setOpenAIModalSucess,
  generatedText,
  setOpenAIModalInProgress,
  setOpenAIModal,
  aiCall,
  setUserInput,
  setOpenAddBiomodal,
  setOpenEditProjectHistory,
  setOpenJobSummary,
  userInput,
}) => {
  // console.log("aicall", aiCall);
  const { pathname } = useLocation();

  const handleCopyText = () => {
    navigator.clipboard.writeText(generatedText);
    setOpenAIModalSucess(false);
    setOpenAIModalInProgress(false);
    switch (aiCall) {
      case "addProjecthistory":
        if (pathname !== "/registration-process") {
          setOpenAddBiomodal(true);
        }
        break;
      case "editProjecthistory":
        if (pathname !== "/registration-process") {
          setOpenEditProjectHistory(true);
        }
        break;
      case "editJobsummray":
        if (pathname !== "/registration-process") {
          setOpenJobSummary(true);
        }
        break;

      default:
        break;
    }
    setUserInput("");
    // if (aiCall === "addProjecthistory") {
    //   setOpenAddBiomodal(true)
    // }
    // if (aiCall === "editProjecthistory") {
    //   setOpenEditProjectHistory(true)
    // }
    // if (aiCall === "editJobsummray") {
    //   setOpenJobSummary(true)
    // }
    // setOpenModal(!openModal)
  };

  const handleRegenerate = () => {
    setOpenAIModalInProgress(false);
    setOpenAIModalSucess(false);
    setOpenAIModal(true);
  };

  return (
    <div className="overlay">
      <div
        id=""
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className=" bg-[#fff] w-[326px] md:w-[500px] overflow-y-auto overflow-x-hidden"
            ref={refModal}
          >
            <div className="border-b border-[#CED0D8] relative">
              <div className="mx-[50px] my-8">
                <h3 className="text-sm md:text-base tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  {Data?.ai_text}
                </h3>
                <CloseModalButton onClick={() => handleRegenerate()} />
              </div>
            </div>
            <div className="max-h-[400px] md:max-h-[unset] overflow-y-auto">
            <div className="flex flex-col justify-center items-center">
              <h3 className={`font-bold text-base mt-4 text-[#0956FF]`}>
                {Data.generate_success}
              </h3>
            </div>
            <div className="md:mx-5 my-2 p-4 md:p-3">
              <div className="bg-[#F6F6F6] md:px-5">
                <ul className="text-sm font-bold leading-5 px-4 md:px-5 py-2 md:py-3 list-none whitespace-pre-line">
                  {userInput ? (
                    <li>{userInput}</li>
                  ) : (
                    Data.sample_text_ai.map((text, index) => (
                      <li key={index}>{text}</li>
                    ))
                  )}
                </ul>
              </div>
              <div className="flex justify-center items-center my-2 md:my-4">
                <img src={ArrowBlue} alt="DownArrow" className="w-[10px] md:w-[24px] h-[16px] md:h-[15px]" />
              </div>
              <div
                className={`bg-[#E6EEFF] font-normal text-sm px-4 md:px-5 py-2 md:py-3 rounded-sm`}
              >
                {/* <ul className='text-sm leading-5 p-4 list-none whitespace-pre-line'>
                  {
                    generatedText.map((text, index) => <p key={index}> • {text}</p>)
                  }
                </ul> */}
                {generatedText}
              </div>
              <div className="flex justify-between items-center mt-8 mb-3 gap-[10px]">
                <button
                  className="flex-1 border border-[#CED0D8] border-[1] rounded px-3 py-2 font-bold text-xs h-10"
                  onClick={handleRegenerate}
                >
                  やり直す
                </button>
                <button
                  className={`text-white w-[194px] md:w-[265px] lg:w-[358px] bg-gradient-to-r from-[#0956FF] to-[#5389FF] rounded px-auto py-2 h-10 font-bold text-xs`}
                  onClick={handleCopyText}
                >
                  クリップボードにコピー
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIDraftSuccess;
