import { Link } from "react-router-dom";
import logo from "../../Assets/Images/logo-white.png";
import { useLocation } from "react-router-dom";

function Footer() {
  const { pathname } = useLocation();
  const year = new Date().getFullYear();
  return (
    <div
      className={`bg-[#212121] text-[#fff] ${
        pathname === "/registration-process"
          ? "mt-[64px]"
          : "mt-[64px] md:mt-[110px] lg:mt-[150px]"
      }`}
    >
      <div className="flex justify-center pt-[20px] pb-[40px]">
        <div className="flex justify-between w-full md:w-[unset] md:min-w-[700px] lg:min-w-[929px] mx-4 md:mx-0">
          <div>
            <div className="h-[20px] md:h-[35px] w-[127px] md:w-[150px] pt-2">
              <img src={logo} alt="logo" />
            </div>
            <p className="mt-[14px] leading-[15px] text-[9px] md:text-[10px] font-normal">
              {`@${year} 株式会社Plus Synergy All Right Reserved.`}
            </p>
          </div>
          <div className="flex flex-col gap-[32px] mt-[20px]">
            <div className="flex flex-col md:flex-row gap-2 md:gap-[40px] leading-[20.27px] text-[14px] font-bold">
              <Link to={"https://www.plus-synergy.com/"} target="_blank">
                <p>運営会社</p>
              </Link>
              <Link
                to={
                  "https://torpid-apparel-d15.notion.site/ab85786367ed47958f98950a60a6b91f"
                }
                target="_blank"
              >
                <p>利用規約</p>
              </Link>
              <Link
                to={
                  "https://torpid-apparel-d15.notion.site/3e962470eabd48d5a2c9ccc7e773a809?pvs=25"
                }
                target="_blank"
              >
                <p>プライバシーポリシー</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;