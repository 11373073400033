import { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Formik } from "formik";
// import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import PlusIcon from "../../Assets/icons/plusIcon-blue.svg";
import OpenChev from "../../Assets/icons/openChev.svg";
import pinIcon from "../../Assets/icons/pinicon.svg";
import Data from "../../JSON/data.json";
import Loader from "../buttons/Loader";
import CloseModalButton from "../buttons/CloseModalButton";
import { updateBioProjectHistory } from "../validation/FormikValidation";
import {
  useGetProjectHistoryByIdMutation,
  useUpdateProjectHistoryMutation,
} from "../../Store/slice/ProjectSlice";
import { ContextData } from "../../Store/Context";
import { scrollToErrors } from "../comman/ScrollToError";
import AI from "../../Assets/icons/ai.svg";

registerLocale("ja", ja);

function EditProjectHistory({
  id,
  setOpenEditProjectHistory,
  setProjectHistory,
  projectHistory,
  refModal,
  setOpenAIModal,
  setSelectedType,
}) {
  const {
    userDetail,
    setUserDetails,
    editProjecthistoryData,
    setEditProjecthistoryData,
  } = useContext(ContextData);
  const CurrentYear = new Date().getFullYear();
  const CurrentMonth = new Date().getMonth() + 1;

  const [getProjectById] = useGetProjectHistoryByIdMutation();
  const [updateProjectHistory, { error, isLoading }] =
    useUpdateProjectHistoryMutation();

  const speciality = Data?.specialty;
  const [open, setOpen] = useState(true);
  const [projectData, setProjectData] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  // console.log("edit data", editProjecthistoryData);

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  const handleClick = (category, sm) => {
    const selectedItem = { category, sm };
    const isSelected = selectedItems.some(
      (item) => item.category === category && item.sm === sm
    );
    if (isSelected) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (item) => !(item.category === category && item.sm === sm)
        )
      );
    } else {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        selectedItem,
      ]);
    }
  };

  useEffect(() => {
    if (editProjecthistoryData) {
      const skillData = editProjecthistoryData?.related_skills?.map((skill) => {
        const key = skill.split(":")[0].replace("{", "").trim();
        const value = skill
          .split(":")[1]
          .trim()
          .replace("}", "")
          .replace("[", "")
          .replace("]", "")
          .trim()
          .split(",");
        return { [key]: value };
      });
      const result = skillData?.reduce((acc, curr) => {
        const [key] = Object.keys(curr);
        const values = curr[key];
        return [
          ...acc,
          ...values.map((value) => {
            return { category: key, sm: value };
          }),
        ];
      }, []);
      setSelectedItems(result);
    }
  }, [editProjecthistoryData]);

  useEffect(() => {
    const getProjectData = async () => {
      const response = await getProjectById(id);
      setProjectData(response?.data?.data?.project_history);
      const skillData =
        response?.data?.data?.project_history?.related_skills?.map((skill) => {
          const key = skill.split(":")[0].replace("{", "").trim();
          const value = skill
            .split(":")[1]
            .trim()
            .replace("}", "")
            .replace("[", "")
            .replace("]", "")
            .trim()
            .split(",");
          return { [key]: value };
        });
      const result = skillData?.reduce((acc, curr) => {
        const [key] = Object.keys(curr);
        const values = curr[key];
        return [
          ...acc,
          ...values.map((value) => {
            return { category: key, sm: value };
          }),
        ];
      }, []);
      setSelectedItems(result);
    };
    if (!editProjecthistoryData) {
      getProjectData();
    }
    // eslint-disable-next-line
  }, [getProjectById, id]);

  // const StartMonthInput = forwardRef(({ value, onClick }, ref) => (
  //   <button
  //     type="button"
  //     className="border pl-5 pt-3 pb-3 border-[#CED0D8] pr-5 react-datepicker-ignore-onclickoutside "
  //     onClick={onClick}
  //     ref={ref}
  //   >
  //     {value.replace("月", "")}
  //   </button>
  // ));

  // const EndMonthInput = forwardRef(
  //   ({ value, onClick, affiliationVal }, ref) => (
  //     <button
  //       type="button"
  //       disabled={affiliationVal}
  //       className={`border pl-5 pt-3 pb-3  pr-5 border-[#CED0D8] react-datepicker-ignore-onclickoutside ${
  //         affiliationVal ? "bg-gray-300" : "bg-white"
  //       }`}
  //       onClick={onClick}
  //       ref={ref}
  //     >
  //       {value.replace("月", "")}
  //     </button>
  //   )
  // );

  const startdate = new Date(projectData?.start_date);
  const startYear = startdate?.getFullYear();
  const startMonth = startdate?.getMonth() + 1;

  const enddate = new Date(projectData?.end_date);
  const endYear = enddate?.getFullYear();
  const endMonth = enddate?.getMonth() + 1;
  return (
    <div className="overlay">
      <div
        id="project-history-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className=" bg-[#fff] w-[326px] md:w-[500px] xl:w-[650px]"
            ref={refModal}
          >
            <div className="border-b border-[#CED0D8] mb-[24px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-sm md:text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[22px] md:leading-[24px]">
                  プロジェクト経歴を編集
                </h3>
                <CloseModalButton
                  onClick={() => {
                    setOpenEditProjectHistory(false);
                    setEditProjecthistoryData("");
                  }}
                />
              </div>
            </div>
            <div className="mt-[24px] mx-[16px] mb-[24px] relative">
              <Formik
                initialValues={{
                  currently_affiliated: editProjecthistoryData
                    ? editProjecthistoryData?.currently_affiliated
                    : projectData?.currently_affiliated,
                  project_name: editProjecthistoryData
                    ? editProjecthistoryData?.project_name
                    : projectData?.project_name,
                  company: editProjecthistoryData
                    ? editProjecthistoryData?.company
                    : projectData?.company,
                  role: editProjecthistoryData
                    ? editProjecthistoryData?.role
                    : projectData?.role,
                  affiliation: editProjecthistoryData
                    ? editProjecthistoryData?.affiliation
                    : projectData?.affiliation,
                  start_year: editProjecthistoryData
                    ? editProjecthistoryData?.start_year
                    : startYear,
                  start_month: editProjecthistoryData
                    ? editProjecthistoryData?.start_month
                    : startMonth,
                  end_year: editProjecthistoryData
                    ? editProjecthistoryData?.end_year
                    : endYear,
                  end_month: editProjecthistoryData
                    ? editProjecthistoryData?.end_month
                    : endMonth,
                  project_overview: editProjecthistoryData
                    ? editProjecthistoryData?.project_overview
                    : projectData?.project_overview,
                  achievements: editProjecthistoryData
                    ? editProjecthistoryData?.achievements_initiatives
                    : projectData?.achievements_initiatives,
                  responsibilities: editProjecthistoryData
                    ? editProjecthistoryData?.responsibilities
                    : projectData?.responsibilities,
                }}
                validationSchema={updateBioProjectHistory}
                enableReinitialize={true}
                innerRef={(ref) => {
                  if (ref?.errors && ref?.isSubmitting === true) {
                    scrollToErrors(ref?.errors);
                  }
                }}
                onSubmit={async (values, { resetForm, setErrors }) => {
                  if (values.start_year === values.end_year) {
                    if (values.start_month > values.end_month) {
                      setErrors({ end_month: "end month is not valid" });
                      return;
                    }
                  }

                  //  Date Converted
                  const start = new Date(
                    values.start_year.toString() +
                      "-" +
                      values.start_month.toString() +
                      "-" +
                      "01"
                  )
                    .toLocaleDateString("ja-JP")
                    .replaceAll("/", "-");
                  const end = new Date(
                    values.end_year.toString() +
                      "-" +
                      values.end_month.toString() +
                      "-" +
                      "30"
                  )
                    .toLocaleDateString("ja-JP")
                    .replaceAll("/", "-");

                  // Get Selected Values From Related Skills
                  function selectedCategory() {
                    var categories = [];
                    function unique(value, index, array) {
                      return array.indexOf(value) === index;
                    }

                    var newCategories = selectedItems
                      .map((item) => item.category)
                      .filter(unique);

                    newCategories.forEach((categorie) => {
                      let val = [];
                      selectedItems.forEach((item) => {
                        if (categorie === item.category) {
                          val.push(item.sm);
                        }
                      });
                      categories.push({ [categorie]: val });
                    });
                    return categories;
                  }
                  const categories = selectedCategory();

                  const data = {
                    project_history: {
                      currently_affiliated: values?.currently_affiliated,
                      project_name: values.project_name,
                      company: values.company,
                      role: values.role,
                      affiliation: values.affiliation,
                      project_overview: values.project_overview,
                      responsibilities: values?.responsibilities?.filter(
                        (item) => item !== ""
                      ),
                      achievements_initiatives: values.achievements,
                      start_date: start,
                      end_date: values.currently_affiliated ? null : end,
                      related_skills: categories.map((obj) => {
                        const key = Object.keys(obj)[0];
                        const value = obj[key];
                        return `{ ${key}: [${value.map((val) => `${val}`)}] }`;
                      }),
                    },
                  };
                  const response = await updateProjectHistory({
                    ...data,
                    user_id: id,
                  });

                  if (response?.error) {
                    toast.error(
                      response?.error?.data?.errors[0]?.detail ||
                        "何か問題が発生しました"
                    );
                  }

                  if (response?.data?.success === true) {
                    toast.success(
                      response?.data?.message || "変更は正常に保存されました"
                    );
                    setOpenEditProjectHistory(false);
                    setProjectHistory(
                      projectHistory.map((item) =>
                        item?.id !== response?.data?.data?.project_history?.id
                          ? item
                          : response?.data?.data?.project_history
                      )
                    );
                    setUserDetails({
                      ...userDetail,
                      details: {
                        ...userDetail?.details,
                        project_histories: projectHistory.map((item) =>
                          item?.id !== response?.data?.data?.project_history?.id
                            ? item
                            : response?.data?.data?.project_history
                        ),
                      },
                    });
                    setEditProjecthistoryData("");
                    resetForm();
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="h-[400px] xl:h-[500px] overflow-y-auto">
                      <h1 className="text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] font-bold text-[#878787] mb-[16px]">
                        プロジェクト経歴
                      </h1>
                      <div className="mt-[16px]">
                        <div className="md:flex md:gap-[16px] md:items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                          <div className="w-[115px] mb-2 md:mb-0 text-black">
                            <label htmlFor="project_name">プロジェクト名</label>
                          </div>
                          <input
                            name="project_name"
                            type="text"
                            placeholder="プロジェクト名"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.project_name}
                            className="h-[40px] md:h-[48px] w-[286px] break-words md:w-[340px] xl:w-[461px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-none"
                          />
                        </div>
                        {errors.project_name && touched.project_name && (
                          <p className="text-red-600 text-[12px] ml-[128px]">
                            *{errors.project_name}
                          </p>
                        )}
                      </div>
                      <div className="mt-[16px]">
                        <div className="md:flex md:gap-[16px] md:items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                          <div className="w-[115px] mb-2 md:mb-0 text-black">
                            <label htmlFor="company">クライアント業種</label>
                          </div>
                          <input
                            name="company"
                            type="text"
                            placeholder="製造業"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.company}
                            className="h-[40px] md:h-[48px] w-[286px] break-words md:w-[340px] xl:w-[461px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-none"
                          />
                        </div>
                        {errors.company && touched.company && (
                          <p className="text-red-600 text-[12px] ml-[128px]">
                            *{errors.company}
                          </p>
                        )}
                      </div>
                      <div className="mt-[16px]">
                        <div className="md:flex md:gap-[16px] md:items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                          <div className="w-[115px] mb-2 md:mb-0 text-black">
                            <label htmlFor="role">役割</label>
                          </div>
                          <input
                            name="role"
                            type="text"
                            placeholder="プロジェクトマネージャー / アシスタント / PMO"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.role}
                            className="h-[40px] md:h-[48px] w-[286px] break-words md:w-[340px] xl:w-[461px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-none"
                          />
                        </div>
                        {errors.role && touched.role && (
                          <p className="text-red-600 text-[12px] ml-[128px]">
                            *{errors.role}
                          </p>
                        )}
                      </div>
                      <div className="mt-[16px]">
                        <div className="md:flex md:gap-[16px] md:items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                          <div className="w-[115px] mb-2 md:mb-0 text-black">
                            <label htmlFor="affiliation">所属</label>
                          </div>
                          <input
                            name="affiliation"
                            type="text"
                            placeholder="サンプル株式会社 / フリーランス"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.affiliation}
                            className="h-[40px] md:h-[48px] w-[286px] break-words md:w-[340px] xl:w-[461px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-none"
                          />
                        </div>
                        {errors.affiliation && touched.affiliation && (
                          <p className="text-red-600 text-[12px] ml-[128px]">
                            *{errors.affiliation}
                          </p>
                        )}
                      </div>
                      <div className="mt-[16px]">
                        <div className="md:flex md:gap-[16px] md:items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                          <div className="w-[120px] xl:w-[115px] mb-2 md:mb-0 text-black">
                            <label htmlFor="affiliation_period ">
                              稼働期間
                            </label>
                          </div>
                          <div className="font-normal">
                            <div className="md:flex md:items-center">
                              <div className="flex items-center mb-1">
                                {/* <DatePicker
                                className="picker"
                                name="start_year"
                                selected={
                                  values.start_year
                                    ? new Date().setFullYear(values.start_year)
                                    : new Date().setFullYear("2010")
                                }
                                minDate={new Date().setFullYear(1900)}
                                maxDate={new Date()}
                                onBlur={(date) => {
                                  setFieldValue(
                                    "start_year",
                                    date.target.value || "2010"
                                  );
                                }}
                                onChange={(date) => {
                                  setFieldValue(
                                    "start_year",
                                    date?.getFullYear()
                                  );
                                }}
                                showYearPicker
                                dateFormat="YYYY"
                              /> */}
                                <input
                                  name="start_year"
                                  type="number"
                                  min={1900}
                                  max={new Date().getFullYear()}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.start_year}
                                  className="w-[65px] h-[40px] md:h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px]"
                                />
                                <p className="px-[8px]">年</p>
                                {/* <DatePicker
                                className="picker"
                                locale="ja"
                                name="start_month"
                                selected={
                                  values.start_month
                                    ? new Date().setMonth(
                                        values.start_month - 1
                                      )
                                    : new Date()
                                }
                                onBlur={(date) => {
                                  setFieldValue(
                                    "start_month",
                                    values?.start_month ||
                                      new Date().getMonth() + 1
                                  );
                                }}
                                onChange={(date) => {
                                  setFieldValue(
                                    "start_month",
                                    date?.toLocaleString("default", {
                                      month: "2-digit",
                                    })
                                  );
                                }}
                                dateFormat="MMM"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                customInput={<StartMonthInput />}
                              /> */}
                                <input
                                  name="start_month"
                                  type="number"
                                  min={1}
                                  max={12}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.start_month}
                                  className="w-[50px] h-[40px] md:h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px]"
                                />
                                <div className="flex">
                                  <p className="px-[8px]">月</p>
                                  <p className="px-[8px]">〜</p>
                                </div>
                              </div>
                              {/* <DatePicker
                                className={`picker ${
                                  values?.currently_affiliated
                                    ? "bg-gray-300"
                                    : "bg-white"
                                }`}
                                disabled={values?.currently_affiliated}
                                name="end_year"
                                selected={
                                  values.end_year
                                    ? new Date().setFullYear(values.end_year)
                                    : new Date()
                                }
                                minDate={new Date().setFullYear(1900)}
                                maxDate={new Date()}
                                onBlur={(date) => {
                                  setFieldValue(
                                    "end_year",
                                    date.target.value ||
                                      new Date().getFullYear()
                                  );
                                }}
                                onChange={(date) => {
                                  setFieldValue(
                                    "end_year",
                                    date?.getFullYear()
                                  );
                                }}
                                showYearPicker
                                dateFormat="yyyy"
                              /> */}
                              <div className="flex items-center">
                                <input
                                  name="end_year"
                                  type="number"
                                  min={1900}
                                  max={new Date().getFullYear()}
                                  disabled={values?.currently_affiliated}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.end_year}
                                  className={`w-[65px] h-[40px] md:h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px] ${
                                    values?.currently_affiliated
                                      ? "bg-gray-300"
                                      : "bg-white"
                                  }`}
                                />
                                <p className="px-[8px]">年</p>
                                {/* <DatePicker
                                className={`picker ${
                                  values?.currently_affiliated
                                    ? "bg-gray-300"
                                    : "bg-white"
                                }`}
                                locale="ja"
                                name="end_month"
                                selected={
                                  values.end_month
                                    ? new Date().setMonth(values.end_month - 1)
                                    : new Date()
                                }
                                onChange={(date) => {
                                  setFieldValue(
                                    "end_month",
                                    date?.toLocaleString("default", {
                                      month: "2-digit",
                                    })
                                  );
                                }}
                                onBlur={(date) => {
                                  setFieldValue(
                                    "end_month",
                                    values?.end_month
                                      ? values?.end_month
                                      : new Date().getMonth() + 1
                                  );
                                }}
                                dateFormat="MMM"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                customInput={
                                  <EndMonthInput
                                    affiliationVal={
                                      values?.currently_affiliated
                                    }
                                  />
                                }
                              /> */}
                                <input
                                  name="end_month"
                                  type="number"
                                  min={1}
                                  max={
                                    values?.end_year ===
                                    new Date().getFullYear()
                                      ? new Date().getMonth() + 1
                                      : 12
                                  }
                                  disabled={values?.currently_affiliated}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.end_month}
                                  className={`w-[50px] h-[40px] md:h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px] ${
                                    values?.currently_affiliated
                                      ? "bg-gray-300"
                                      : "bg-white"
                                  }`}
                                />
                                <p className="px-[8px]">月</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-self-start">
                          <div>
                            {errors.start_year && touched.start_year && (
                              <p className="text-red-600 text-[12px] md:ml-[128px]">
                                *{errors.start_year}
                              </p>
                            )}
                            {errors.start_month && touched.start_month && ( 
                              <p className="text-red-600 text-[12px] md:ml-[128px]">
                                *{errors.start_month}
                              </p>
                            )}
                          </div>
                          <div>
                            {errors.end_year && touched.end_year && (
                              <p className="text-red-600 text-[12px] ml-[40px] md:ml-[135px]">
                                *{errors.end_year} 
                              </p>
                            )}
                            {errors.end_month && touched.end_month && (
                              <p className="text-red-600 text-[12px] ml-[40px] md:ml-[135px]">
                                *{errors.end_month}
                              </p>
                            )}
                          </div>
                        </div>
                        <label className="cursor-pointer text-xs mt-[16px] w-fit gap-3 text-center flex justify-start items-end md:ml-[130px]">
                          <input
                            name="currently_affiliated"
                            type="checkbox"
                            disabled={
                              !values?.start_year || !values?.start_month
                            }
                            checked={values?.currently_affiliated}
                            className="h-[18px] w-[18px] cursor-pointer"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue(
                                "currently_affiliated",
                                e.target.checked
                              );
                              if (e.target.checked === true) {
                                setFieldValue("end_year", CurrentYear);
                                setFieldValue("end_month", CurrentMonth);
                              }
                              if (e.target.checked === false) {
                                setFieldValue(
                                  "end_year",
                                  endYear ? endYear : ""
                                );
                                setFieldValue(
                                  "end_month",
                                  endMonth ? endMonth : ""
                                );
                              }
                            }}
                          />
                          現在も稼働している
                        </label>
                      </div>
                      <div className="flex justify-between items-center mt-4 mb-4">
                        <h6 className="text-[14px] leading-[22px] font-normal text-[#212121]">
                          プロジェクト概要
                        </h6>
                        <div className="flex justify-end items-center my-2 rounded bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px]">
                          <button
                            type="button"
                            className="text-[#0956FF] w-[140px] h-fit  md:w-fit bg-white py-2 md:py-[12px] px-3 md:px-[16px] rounded font-bold flex items-center text-[12px]"
                            onClick={() => {
                              setOpenAIModal(true);
                              setOpenEditProjectHistory(false);
                              setSelectedType(1);

                              // Get Selected Values From Related Skills
                              function selectedCategory() {
                                var categories = [];
                                function unique(value, index, array) {
                                  return array.indexOf(value) === index;
                                }

                                var newCategories = selectedItems
                                  .map((item) => item.category)
                                  .filter(unique);

                                newCategories.forEach((categorie) => {
                                  let val = [];
                                  selectedItems.forEach((item) => {
                                    if (categorie === item.category) {
                                      val.push(item.sm);
                                    }
                                  });
                                  categories.push({ [categorie]: val });
                                });
                                return categories;
                              }
                              const categories = selectedCategory();

                              const data = {
                                project_history: {
                                  currently_affiliated:
                                    values?.currently_affiliated,
                                  project_name: values.project_name,
                                  company: values.company,
                                  role: values.role,
                                  affiliation: values.affiliation,
                                  project_overview: values.project_overview,
                                  responsibilities:
                                    values?.responsibilities?.filter(
                                      (item) => item !== ""
                                    ),
                                  achievements_initiatives: values.achievements,
                                  start_year: values?.start_year,
                                  start_month: values?.start_month,
                                  end_year: values?.end_year,
                                  end_month: values?.end_month,
                                  related_skills: categories.map((obj) => {
                                    const key = Object.keys(obj)[0];
                                    const value = obj[key];
                                    return `{ ${key}: [${value.map(
                                      (val) => `${val}`
                                    )}] }`;
                                  }),
                                },
                              };
                              setEditProjecthistoryData(data?.project_history);
                            }}
                          >
                            <span>
                              <img src={AI} alt="FileSvg" />
                            </span>
                            &nbsp;
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#0956FF] to-[#5389FF]">
                              {Data?.ai_text}
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="w-full py-[8px] px-[16px] border border-[#CED0D8]">
                        <textarea
                          name="project_overview"
                          placeholder="プロジェクト概要"
                          className="text-[14px] font-normal leading-[22px] w-full h-[82px] resize-none outline-none"
                          value={values?.project_overview}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></textarea>
                        {errors.project_overview &&
                          touched.project_overview && (
                            <p className="text-red-600 text-[12px] ">
                              *{errors.project_overview}
                            </p>
                          )}
                      </div>
                      <div className="my-[24px]">
                        <button
                          onClick={() => setOpen(!open)}
                          type="button"
                          className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
                        >
                          {open ? (
                            <img
                              src={OpenChev}
                              alt="cheav"
                              className="transform rotate-[180deg]"
                            />
                          ) : (
                            <img src={OpenChev} alt="cheav" />
                          )}
                          <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                            サンプル記入例
                          </p>
                        </button>
                        {open && (
                          <div className=" bg-[#E6EEFF] h-[auto] pt-[8px] pr-[16px] pb-[8px] pl-[16px] mt-[4px]">
                            <div className="text-[14px] font-normal h-[82px] leading-[22px] text-justify">
                              事業成長を目的としたM＆Aによる投資先選定に際する、投資テーマ・投資領域の評価及び、ロング、ショートリストの作成支援
                            </div>
                          </div>
                        )}
                      </div>
                      <h1 className="text-[16px] leading-[24px] font-bold text-[#878787] mb-[16px]">
                        担当業務
                      </h1>
                      <div>
                        {values?.responsibilities?.map?.((items, index) => {
                          return (
                            <div
                              key={index}
                              className="bg-[#F6F6F6] px-[12px] py-[16px] w-full max-h-[88px] mb-[16px]"
                            >
                              <p className="flex items-center gap-[9px]">
                                <img src={pinIcon} alt="pinIcon" />
                                <input
                                  name={`responsibilities.${index}`}
                                  type="text"
                                  placeholder=""
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `responsibilities.${index}`,
                                      e.target.value
                                    );
                                  }}
                                  value={items}
                                  className="border text-[14px] font-normal w-full leading-[22px] text-[#212121] px-[16px] py-[8px] rounded-[4px] outline-none"
                                />
                              </p>
                              <div className="mt-[8px]">
                                <p
                                  className="text-[#0956FF] text-xs font-normal leading cursor-pointer"
                                  onClick={() => {
                                    const remove =
                                      values?.responsibilities.filter(
                                        (ele) => ele !== items
                                      );
                                    setFieldValue("responsibilities", remove);
                                  }}
                                >
                                  このスキルを削除する
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {errors.responsibilities && touched.responsibilities && (
                        <p className="text-red-600 text-[12px]">
                          *{errors.responsibilities}
                        </p>
                      )}
                      <div className="flex justify-center">
                        <button
                          type="button"
                          className="flex items-center border border-[#212121] py-2 md:py-[13px] px-4 md:px-[68px] w-fit md:w-[280px] h-fit md:h-[48px] rounded-[4px] gap-[4px]"
                          onClick={() =>
                            setFieldValue(
                              `responsibilities.${[
                                values?.responsibilities?.length,
                              ]}`,
                              ""
                            )
                          }
                        >
                          <img
                            src={PlusIcon}
                            alt="PlusIcon"
                            className="h-[12px] w-[12px]"
                          />
                          <p className="text-[#212121] text-[14px] font-bold leading-[22px] w-fit">
                            担当業務を追加する
                          </p>
                        </button>
                      </div>
                      {/* <div className="my-[24px]">
                      <button
                        onClick={() => setOpen(!open)}
                        type="button"
                        className="flex items-center gap-[8px] bg-[#FFEFE3] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
                      >
                        {open ? (
                          <img
                            src={OpenChev}
                            alt="cheav"
                            className="transform rotate-[180deg]"
                          />
                        ) : (
                          <img src={OpenChev} alt="cheav" />
                        )}
                        <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                          サンプル記入例
                        </p>
                      </button>
                      {open && (
                        <div className="text-[14px] font-normal leading-[22px]">
                          {sample_text?.map((text, index) => {
                            return (
                              <p
                                key={index}
                                className="flex items-center gap-[9px] mt-2"
                              >
                                <input
                                  type="text"
                                  disabled
                                  value={text}
                                  className="text-[14px] font-normal w-full leading-[22px] bg-[#FFEFE3] px-[16px] py-[8px] rounded-[4px] outline-none"
                                />
                              </p>
                            );
                          })}
                        </div>
                      )}
                    </div> */}
                      <div className="flex justify-between items-center mt-6 mb-4">
                        <h1 className="text-[16px] leading-[24px] font-bold text-[#878787]">
                          実績/取り組み
                        </h1>
                        <div className="flex justify-end items-center my-2 rounded bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px]">
                          <button
                            type="button"
                            className="text-[#0956FF] h-fit w-fit bg-white py-[12px] px-[16px] rounded font-bold flex items-center text-[8px] md:text-[10px] xl:text-[12px]"
                            onClick={() => {
                              setOpenAIModal(true);
                              setOpenEditProjectHistory(false);
                              setSelectedType(2);
                              // Get Selected Values From Related Skills
                              function selectedCategory() {
                                var categories = [];
                                function unique(value, index, array) {
                                  return array.indexOf(value) === index;
                                }

                                var newCategories = selectedItems
                                  .map((item) => item.category)
                                  .filter(unique);

                                newCategories.forEach((categorie) => {
                                  let val = [];
                                  selectedItems.forEach((item) => {
                                    if (categorie === item.category) {
                                      val.push(item.sm);
                                    }
                                  });
                                  categories.push({ [categorie]: val });
                                });
                                return categories;
                              }
                              const categories = selectedCategory();

                              const data = {
                                project_history: {
                                  currently_affiliated:
                                    values?.currently_affiliated,
                                  project_name: values.project_name,
                                  company: values.company,
                                  role: values.role,
                                  affiliation: values.affiliation,
                                  project_overview: values.project_overview,
                                  responsibilities:
                                    values?.responsibilities?.filter(
                                      (item) => item !== ""
                                    ),
                                  achievements_initiatives: values.achievements,
                                  start_year: values?.start_year,
                                  start_month: values?.start_month,
                                  end_year: values?.end_year,
                                  end_month: values?.end_month,
                                  related_skills: categories.map((obj) => {
                                    const key = Object.keys(obj)[0];
                                    const value = obj[key];
                                    return `{ ${key}: [${value.map(
                                      (val) => `${val}`
                                    )}] }`;
                                  }),
                                },
                              };
                              setEditProjecthistoryData(data?.project_history);
                            }}
                          >
                            <span>
                              <img src={AI} alt="FileSvg" />
                            </span>
                            &nbsp;
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#0956FF] to-[#5389FF]">
                              {Data?.ai_text}
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="w-full border border-[#CED0D8]">
                        <textarea
                          name="achievements"
                          placeholder="実績/取り組み"
                          className="text-[14px] font-normal leading-[22px] w-full h-[174px] resize-none outline-none  py-[8px] px-[16px]"
                          value={values?.achievements}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></textarea>
                        {errors.achievements && touched.achievements && (
                          <p className="text-red-600 text-[12px] ">
                            *{errors.achievements}
                          </p>
                        )}
                      </div>
                      <div className="my-[24px]">
                        <button
                          onClick={() => setOpen(!open)}
                          type="button"
                          className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
                        >
                          {open ? (
                            <img
                              src={OpenChev}
                              alt="cheav"
                              className="transform rotate-[180deg]"
                            />
                          ) : (
                            <img src={OpenChev} alt="cheav" />
                          )}
                          <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                            サンプル記入例
                          </p>
                        </button>
                        {open && (
                          <div className=" bg-[#E6EEFF] h-[auto] pt-[8px] pr-[16px] pb-[8px] pl-[16px] mt-[4px]">
                            <div className="text-[14px] font-normal leading-[22px] text-justify h-auto">
                              グローバル市場におけるサプライチェーンの動向分析を実施し、業界内外のベストプラクティスを綿密に調査。ターゲットとなる市場セグメントおよび業界を特定し、クライアントのビジネス戦略に沿ったM&Aの方向性を策定。業界の専門家やアドバイザーとの連携を通じ、広範なネットワークから有望な企業群を抽出し、ロングリストを作成した。成果としてクライアントのM＆Aの意思決定に貢献
                            </div>
                          </div>
                        )}
                      </div>
                      <h1 className="text-[14px] md:text-[16px] leading-[24px] font-bold text-[#878787] mb-[16px]">
                        関連スキル
                      </h1>

                      {speciality?.map((items) => {
                        return (
                          <div
                            key={items?.id}
                            className="mb-[16px] md:mb-[24px]"
                          >
                            <h1 className="text-[12px] md:text-[16px] leading-[24px] font-normal text-[#212121] mb-[8px]">
                              {items?.category}
                            </h1>
                            <div className="flex gap-[8px] items-center flex-wrap">
                              {items?.subcategories?.map((category) => {
                                const isSelected = selectedItems.some(
                                  (item) =>
                                    item.category === items?.category &&
                                    item.sm === category?.sm
                                );
                                return (
                                  <p
                                    onClick={() =>
                                      handleClick(items?.category, category?.sm)
                                    }
                                    key={category?.id}
                                    className={`text-[14px] cursor-pointer font-normal leading-[22px] py-[3px] md:py-[6px] rounded-full px-[12px] md:px-[16px] ${
                                      isSelected
                                        ? `text-[#0956FF] bg-[#E6EEFF] border border-[#0956FF]`
                                        : "border-0 bg-[#F6F6F6] text-[#878787]"
                                    } w-[fit-content] rounded-full`}
                                  >
                                    {category?.sm}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                      <div className="mt-[16px]">
                        <div className="flex gap-[10px] items-center justify-center">
                          <button
                            type="button"
                            className="w-[99px] h-[40px] py-[9px] px-[15px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                            onClick={() => {
                              setOpenEditProjectHistory(false);
                              setEditProjecthistoryData("");
                            }}
                          >
                            キャンセル
                          </button>
                          <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-[194px] md:w-[358px] h-[40px] ${
                              isLoading
                                ? "bg-neutral-500"
                                : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                            } rounded-[4px] text-xs font-bold text-[#fff]`}
                          >
                            {isLoading ? (
                              <div className="flex justify-center">
                                <Loader />
                              </div>
                            ) : (
                              "保存する"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditProjectHistory;
