import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import NoteIcon from "../Assets/icons/noteIcon.svg";
import noteIcon from "../Assets/icons/note.svg";
import Address from "../Assets/icons/Addressicon.svg";
import OpenChev from "../Assets/icons/openChev.svg";

import Data from "../JSON/data.json";
import { ContextData } from "../Store/Context";
// import { SetworkHistory } from "../Components/ExcelJs/SetworkHistory";
// import { SetProgectHistory } from "../Components/ExcelJs/SetProgectHistory";
import JobPrLoader from "../Components/Loader/JobPrLoader";
import SkillsLoader from "../Components/Loader/SkillsLoader";
import SpecialityLoader from "../Components/Loader/SpecialityLoader";
import WorkHistoryLoader from "../Components/Loader/WorkHistoryLoader";
import {
  useOutputResumeExcelMutation,
  useOutputResumeMutation,
  useOutputResumePDFMutation,
} from "../Store/slice/OutputResume";
import Loader from "../Components/buttons/Loader";

const OutputResume = () => {
  const navigate = useNavigate();
  const name_notation = Data?.name_notation;
  const file_type = Data?.file_type;
  const [userDetails, setUserDetails] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [id, setId] = useState([]);
  // eslint-disable-next-line
  // const [nameNotations, setNameNotations] = useState("しない");
  // eslint-disable-next-line
  const { userDetail, userProfile } = useContext(ContextData);
  // eslint-disable-next-line
  const [fileupload, { isLoading }] = useOutputResumeMutation();
  // eslint-disable-next-line
  const [fileType, setFileType] = useState("New-PDF");
  // eslint-disable-next-line
  const [getExcelFile, { error: excelError, isLoading: excelLoading }] =
    useOutputResumeExcelMutation();
  // eslint-disable-next-line
  const [getPdfFile, { error: pdfError, isLoading: pdfLoading }] = useOutputResumePDFMutation();

  useEffect(() => {
    setUserDetails(userDetail?.details);
  }, [userDetail]);

  // Speciality convert array to object
  useEffect(() => {
    const abc = userDetails?.specialities?.map((speciality) => {
      const key = speciality?.split(":")[0].replace("{", "").trim();
      const value = speciality
        .split(":")[1]
        .trim()
        .replace("}", "")
        .replace("[", "")
        .replace("]", "")
        .trim()
        .split(",");
      return { [key]: value };
    });
    setSpeciality(abc);
  }, [userDetails]);

  // Expansion of project in list
  const handleOpen = (projectId) => {
    if (projectId === id?.find((item) => item === projectId)) {
      setId(id?.filter((item) => item !== projectId));
    } else {
      setId([...id, projectId]);
    }
  };
  console.log("file", fileType);
  return (
    <div className="mt-[24px] md:mt-[72px] max-w-[1005px] mx-auto">
      <div className="text-[#212121]">
        <h1 className="flex items-center justify-center font-bold text-[18px] md:text-[20px] lg:text-[24px] leading-[22px] ">
          履歴書の出力
        </h1>
        <p className="flex items-center justify-center text-[12px] md:text-[14px] font-normal mt-[8px]">
          出力する履歴書に記載する内容を選択してください。
        </p>
      </div>

      <Formik
        initialValues={{
          notation: "しない",
          job_summary: userDetails?.job_summary ? userDetails?.job_summary : "",
          specialty: speciality,
          skill: userDetails?.skills ? userDetails?.skills : [],
          work_history: userDetails?.work_histories ? userDetails?.work_histories : [],
          Project_history: userDetails?.project_histories ? userDetails?.project_histories : [],
        }}
        enableReinitialize={true}
        // validationSchema={OutputResumeSchema}
        onSubmit={async (values) => {
          const data = {
            "name": values?.notation === "しない" ? false : true,
            "new_pdf": fileType === "Excel" ? false : fileType === "Old-PDF" ? false : true,
            "skills": values?.skill,
            "work_history_ids": values?.work_history?.map((e) => e.id),
            "project_history_ids": values?.Project_history?.map((e) => e.id)
          }
          var response = ""
          if (fileType === "Excel") {
            response = await getExcelFile(data);
          } else {
            response = await getPdfFile(data);
          }

          if (response?.error?.data?.errors) {
            toast.error(
              response?.error?.message ||
              "あなたの履歴書はアップロードされていません。"
            );
          }
          if (response?.data?.success === true) {
            if (fileType === "Old-PDF" || fileType === "New-PDF") {

              fetch(response?.data?.data?.resume_url).then((response) => {
                response.blob().then((blob) => {
                  // Creating new object of PDF file
                  const fileURL =
                    window.URL.createObjectURL(blob);

                  // Setting various property values for Download
                  const alink = document.createElement("a");
                  alink.href = fileURL;
                  alink.download = "resume.pdf";
                  alink.click();
                  toast.success(
                    response?.data?.message ||
                    "履歴書は正常にアップロードされました。"
                  );
                }).catch((error) => {
                  toast.error(
                    response?.error?.message ||
                    "あなたの履歴書はアップロードされていません。"
                  );
                });
              }).catch((error) => {
                toast.error(
                  response?.error?.message ||
                  "あなたの履歴書はアップロードされていません。"
                );
              });
            }
            else {
              const anchor = document.createElement("a");
              anchor.href = response?.data?.data?.resume_url;
              anchor.download = "resume.xlxs";
              anchor.click();
              toast.success(
                response?.data?.message ||
                "履歴書は正常にアップロードされました。"
              );
            }
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="mx-[16px]">
            <div>
              {/* File selection Excel or PDF */}
              <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                  <div>
                    <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                      データ形式
                    </h1>
                  </div>
                </div>
                <div className="mt-[16px] md:mt-[12px] lg:mt-[16px]">
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="mt-[8px] flex items-center flex-wrap"
                  >
                    {file_type?.map((item) => {
                      return (
                        <label
                          className="cursor-pointer mr-[24px] flex py-1 items-center text-sm md:text-base"
                          key={item?.id}
                        >
                          <input
                            name="file_type"
                            type="radio"
                            value={item?.title}
                            onChange={(e) => setFileType(e.target.value)}
                            className="mr-[8px] font-normal text-[12px] lg:text-[14px] text-[#212121]"
                            defaultChecked={item?.check}
                          />
                          {item?.titleToDisplay}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </section>

              {/* Selection of Do or Do not */}
              <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                  <div>
                    <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                      名前の表記
                    </h1>
                  </div>
                </div>
                <div className="mt-[16px] md:mt-[12px] lg:mt-[16px]">
                  <label
                    id="my-radio-group"
                    className="text-[#878787] font-bold text-[12px] lg:text-[14px]"
                  >
                    ノンネーム表記
                  </label>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="mt-[8px] flex items-center flex-wrap"
                  >
                    {name_notation?.map((item) => {
                      return (
                        <label
                          className="cursor-pointer mr-[24px] py-1 flex items-center text-sm md:text-base"
                          key={item?.id}
                        >
                          <input
                            name="notation"
                            type="radio"
                            value={item?.title}
                            onChange={handleChange}
                            className="mr-[8px] font-normal text-[12px] lg:text-[14px] text-[#212121]"
                            defaultChecked={item?.check}
                          />
                          {item?.title}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </section>

              {/* Job summmary */}
              <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                <div className="flex items-center justify-between pb-[12px] lg:pb-[16px] border-b">
                  <div>
                    <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                      職務要約
                    </h1>
                  </div>
                </div>
                <div className="font-normal text-[14px] md:text-[12px] lg:text-[14px] text-[#212121] mt-[16px] md:mt-[12px] lg:mt-[16px] leading-[24px] md:leading-[22px]">
                  {userDetail?.isLoading ? (
                    <JobPrLoader />
                  ) : userDetails?.job_summary?.length ? (
                    userDetails?.job_summary
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>

              {/* Specialty */}
              <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b mb-[16px]">
                  <div>
                    <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                      得意分野
                    </h1>
                  </div>
                </div>
                <div className="mt-[16px] md:mt-[12px] lg:mt-[16px]">
                  {userDetail?.isLoading ? (
                    <SpecialityLoader />
                  ) : speciality?.length ? (
                    speciality?.map((specialitys, index) => {
                      return (
                        <div key={index} className="mt-4">
                          <h1 className="text-[14px] md:text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                            {Object.keys(specialitys)}
                          </h1>
                          <div className="flex mt-[8px] gap-[8px] flex-wrap">
                            {Object.values(specialitys)[0].map(
                              (category, idx) => {
                                const isSelected = values?.specialty?.find(
                                  (ele) => {
                                    return Object.values(ele)[0].includes(
                                      category
                                    );
                                  }
                                );
                                return (
                                  <div
                                    onClick={(e) => {
                                      var selectedKey =
                                        Object.keys(specialitys);
                                      if (isSelected) {
                                        const for_remove = values.specialty
                                          .map((ele) => {
                                            if (ele[selectedKey]) {
                                              const filteredArray =
                                                Object.values(
                                                  ele[selectedKey]
                                                ).filter(
                                                  (aaa) =>
                                                    aaa !== e.target.textContent
                                                );
                                              if (filteredArray.length === 0) {
                                                return null;
                                              }
                                              return {
                                                [selectedKey]: filteredArray,
                                              };
                                            }
                                            return ele;
                                          })
                                          .filter(Boolean); // use for handle null value
                                        setFieldValue("specialty", for_remove);
                                      } else {
                                        const for_add = values?.specialty?.map(
                                          (ele) => {
                                            if (ele[selectedKey]) {
                                              const qqq = [
                                                ...Object.values(ele)[0],
                                                e.target.textContent,
                                              ];
                                              return {
                                                [selectedKey]: qqq,
                                              };
                                            } else {
                                              return ele;
                                            }
                                          }
                                        );
                                        setFieldValue("specialty", for_add);
                                      }
                                    }}
                                    key={idx}
                                    className={`font-normal  cursor-pointer text-[12px] lg:text-[14px] px-[16px] py-[6px] leading-[22px] ${isSelected
                                      ? `text-[#0956FF] bg-[#E6EEFF] border border-[#0956FF]`
                                      : "border-0 bg-[#F6F6F6] text-[#878787]"
                                      } w-[fit-content] rounded-full`}
                                  >
                                    {category}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>

              {/* Skills */}
              <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b mb-[16px]">
                  <div>
                    <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                      活かせるスキル・資格
                    </h1>
                  </div>
                </div>
                <div className="mt-[16px] md:mt-[12px] lg:mt-[16px]">
                  {userDetail?.isLoading ? (
                    <SkillsLoader />
                  ) : userDetails?.skills?.length ? (
                    userDetails?.skills?.map((items) => {
                      return (
                        <label key={items?.id} className="mt-2 md:mt-4">
                          <div
                            key={items?.id}
                            className="cursor-pointer mt-[16px] flex items-center gap-[8px] bg-[#F6F6F6] py-[12px] px-[16px]"
                          >
                            <input
                              name="skill"
                              type="checkbox"
                              className="h-[18px] w-[18px]"
                              value={items?.skill}
                              defaultChecked
                              onBlur={handleBlur}
                              onChange={(e) => {
                                const checkedvalue = e.target.checked;
                                const updatedSkills = checkedvalue
                                  ? [...values.skill, items]
                                  : values.skill.filter(
                                    (skill) => skill !== items
                                  );
                                setFieldValue("skill", updatedSkills);
                              }}
                            />
                            <img src={NoteIcon} alt="NoteIcon" />
                            <span className="font-normal text-[12px] lg:text-[14px] leading-[22px] tetx-[#212121]">
                              {items}
                            </span>
                          </div>
                        </label>
                      );
                    })
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>

              {/* Work history  */}
              <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px] w-full" id="section4" >
                <div className="flex items-center justify-between mb-[12px] lg:mb-[16px] border-b">
                  <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                    職務経歴(所属経歴)
                  </h1>
                </div>
                <div className="mt-[16px]">
                  {userDetail?.isLoading ? (
                    <WorkHistoryLoader />
                  ) : userDetails?.work_histories?.length ? (
                    userDetails?.work_histories?.map((item, index) => {
                      return (
                        <label
                          className="flex cursor-pointer "
                          key={index}
                          htmlFor={`wrokhistory${index}`}
                        >
                          <input
                            id={`wrokhistory${index}`}
                            name="work_history"
                            type="checkbox"
                            className="h-[18px] w-[18px] mr-2 md:mr-[16px]"
                            value={item}
                            onBlur={handleBlur}
                            defaultChecked
                            onChange={(e) => {
                              const updatedWorkHistory = e.target.checked
                                ? [...values?.work_history, item]
                                : values?.work_history.filter(
                                  (history) => history !== item
                                );
                              setFieldValue("work_history", updatedWorkHistory);
                            }}
                          />
                          <div key={index} className={`flex gap-[8px]`}>
                            <div className="hidden md:flex flex-col justify-between">
                              <h1 className="text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {item?.start_date}
                              </h1>
                              <h1 className="text-[14px] h-[22px] font-bold leading-[22px]">
                                {item?.end_date}
                              </h1>
                            </div>
                            <div className="time-line my-[10px]"></div>
                            <div className="flex flex-col gap-[16px] my-[11px] w-full">
                              <h1 className="block md:hidden mt-[-10px] text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {item?.start_date}
                              </h1>
                              <div>
                                <div>
                                  <div>
                                    <div className="flex justify-between">
                                      <h1 className="flex items-center gap-[4px]">
                                        <img
                                          src={Address}
                                          alt="Address"
                                          className="h-[15px] w-[14px]"
                                        />
                                        <span className="text-[14px] md:text-[12px] lg:text-[14px] font-bold leading-[22px] text-[#212121]">
                                          {item?.company_name}
                                        </span>
                                      </h1>
                                    </div>
                                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                                      <img
                                        src="/Assets/icons/timezoneIcon.svg"
                                        alt="img icon"
                                        className="h-[15px] w-[14px]"
                                      />
                                      <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                                        {item?.employment_type}
                                      </span>
                                    </h1>
                                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                                      <img
                                        src="/Assets/icons/position.svg"
                                        alt="img icon"
                                        className="h-[15px] w-[14px]"
                                      />
                                      <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                                        {item?.position}
                                      </span>
                                    </h1>
                                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                                      <img
                                        src="/Assets/icons/msgIcon.svg"
                                        alt="img icon"
                                        className="h-[15px] w-[14px]"
                                      />
                                      <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                                        {item?.business_details}
                                      </span>
                                    </h1>
                                  </div>
                                </div>
                                <div className="mt-[16px]">
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px]  items-center text-[12px] lg:text-[14px]">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      販売
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.amount_of_sales}
                                    </p>
                                  </div>
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px]  items-center text-[12px] lg:text-[14px]">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      上場
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.listing_type}
                                    </p>
                                  </div>
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px]  items-center text-[12px] lg:text-[14px]">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      就業者数
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.number_of_employees}
                                    </p>
                                    <p className="text-[14px] ml-[8px] font-normal leading-[22px] text-[#212121]">
                                      人
                                    </p>
                                  </div>
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px]  items-center text-[12px] lg:text-[14px]">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      業務内容
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <h1 className="block md:hidden mb-[-10px] text-[14px] h-[22px] font-bold leading-[22px]">
                                {item?.end_date}
                              </h1>
                            </div>
                          </div>
                        </label>
                      );
                    })
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>

              {/* Project history */}
              <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px] w-full">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                  <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                    プロジェクト経歴
                  </h1>
                </div>
                <div className="mt-[16px]">
                  {userDetails?.project_histories?.length ? (
                    userDetails?.project_histories?.map((items, index) => {
                      const skillData = items?.related_skills?.map((skill) => {
                        const key = skill.split(":")[0].replace("{", "").trim();
                        var value = skill
                          .split(":")[1]
                          .replace("}", "")
                          .replace("[", "")
                          .replace("]", "")
                          .split(",");
                        const formattedValue = value.join("/");
                        return { [key]: formattedValue };
                      });
                      return (
                        <label
                          className="flex cursor-pointer"
                          key={index}
                          htmlFor={`projectHistory${index}`}
                        >
                          <input
                            id={`projectHistory${index}`}
                            name="Project_history"
                            type="checkbox"
                            className="h-[18px] w-[18px] mr-2 md:mr-[16px]"
                            value={values?.Project_history}
                            defaultChecked
                            onBlur={handleBlur}
                            onChange={(e) => {
                              const updatedProjectHistory = e.target.checked
                                ? [...values?.Project_history, items]
                                : values?.Project_history.filter(
                                  (history) => history !== items
                                );
                              setFieldValue(
                                "Project_history",
                                updatedProjectHistory
                              );
                            }}
                          />
                          <div className="flex gap-[8px]">
                            <div className="hidden md:flex flex-col justify-between cursor-pointer text-[#212121]">
                              <h1 className="text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {items?.start_date}
                              </h1>
                              <h1 className="text-[14px] h-[22px] font-bold leading-[22px]">
                                {items?.end_date}
                              </h1>
                            </div>
                            <div className="time-line my-[10px]"></div>
                            <div className="flex flex-col gap-[4px] my-[11px] w-full">
                              <h1 className="block md:hidden mt-[-10px] text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {items?.start_date}
                              </h1>
                              <div>
                                <div className="flex justify-between">
                                  <h1 className="text-[12px] lg:text-[14px] font-bold leading-[22px] flex items-center gap-[4px]">
                                    <img
                                      src={noteIcon}
                                      alt="noteIcon"
                                      className="h-[15px] w-[14px]"
                                    />
                                    <span className="text-[#212121]">
                                      {items?.project_name}
                                    </span>
                                  </h1>
                                </div>
                                <h1 className="flex items-center mt-[4px] gap-[4px]">
                                  <img
                                    src="/Assets/icons/userIcon.svg"
                                    alt="icon"
                                    className="h-[15px] w-[14px]"
                                  />
                                  <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                                    {items?.company}
                                  </span>
                                </h1>
                                <h1 className="flex items-center mt-[4px] gap-[4px]">
                                  <img
                                    src="/Assets/icons/position.svg"
                                    alt="icon"
                                    className="h-[15px] w-[14px]"
                                  />
                                  <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                                    {items?.role}
                                  </span>
                                </h1>
                                <h1 className="flex items-center mt-[4px] gap-[4px]">
                                  <img
                                    src="/Assets/icons/Addressicon.svg"
                                    alt="icon"
                                    className="h-[15px] w-[14px]"
                                  />
                                  <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                                    {items?.affiliation}
                                  </span>
                                </h1>
                              </div>
                              {id?.find((item) => item === items?.id) ===
                                items?.id && (
                                  <div>
                                    <div className="mt-[16px] w-full">
                                      <div>
                                        <div className="pt-[4px] pr-[0] pl-[0] flex items-center gap-[16px] font-bold text-[12px] lg:text-[14px] leading-[22px]">
                                          <h1 className="text-[#878787]">
                                            プロジェクト概要 :
                                          </h1>
                                        </div>
                                        <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-normal text-[12px] lg:text-[14px]">
                                          <h1 className="text-[#212121] leading-[22px] break-all">
                                            {items?.project_overview}
                                          </h1>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-[16px]">
                                      <div>
                                        <h1 className="text-[#878787] text-[12px] lg:text-[14px] font-bold">
                                          担当業務 :
                                        </h1>
                                        {items?.responsibilities?.map((items) => {
                                          return (
                                            <h1
                                              key={items?.id}
                                              className="text-[12px] lg:text-[14px] font-normal text-[#212121] leading-[22px] flex items-center mt-[4px] gap-[4px] break-all"
                                            >
                                              <img
                                                src="/Assets/icons/pinicon.svg"
                                                alt="icon"
                                              />
                                              <span>{items}</span>
                                            </h1>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="mt-[16px] w-full">
                                      <div>
                                        <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-bold text-[12px] lg:text-[14px]">
                                          <h1 className="text-[#878787]">
                                            実績 / 取り組み :
                                          </h1>
                                        </div>
                                        <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-normal text-[12px] lg:text-[14px]">
                                          <h1 className="text-[#212121] leading-[22px] break-all">
                                            {items?.achievements_initiatives}
                                          </h1>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-[16px]">
                                      <div>
                                        <h1 className="text-[#878787]">
                                          関連スキル :
                                        </h1>
                                      </div>
                                      {skillData?.map((skill, index) => {
                                        return (
                                          <div key={index}>
                                            <h1 className="text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                                              {Object.keys(skill)}
                                            </h1>
                                            <div className="flex mt-[8px] gap-[8px] flex-wrap">
                                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                                {Object.values(skill)}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              <button
                                type="button"
                                className="mt-[16px] flex items-center gap-[2px]"
                                onClick={() => {
                                  setId(items?.id);
                                  handleOpen(items?.id);
                                }}
                              >
                                <p className="text-[12px] font-bold text-[#0956FF]">
                                  {Data?.view_details_button}
                                </p>
                                <span>
                                  {id?.find((item) => item === items?.id) ===
                                    items?.id ? (
                                    <img
                                      src={OpenChev}
                                      alt="cheav"
                                      className="transform rotate-[180deg]"
                                    />
                                  ) : (
                                    <img src={OpenChev} alt="cheav" />
                                  )}
                                </span>
                              </button>
                              <h1 className="block md:hidden mb-[-10px] text-[14px] h-[22px] font-bold leading-[22px]">
                                {items?.end_date}
                              </h1>
                            </div>
                          </div>
                        </label>
                      );
                    })
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>

              {/* Submit button */}
              <div className="mt-6 md:mt-[40px] flex justify-center px-4 md:px-0">
                <div className="flex gap-[10px] items-center">
                  <button
                    onClick={() => {
                      resetForm();
                      navigate("/profile");
                    }}
                    type="button"
                    className="w-[90px] md:w-[99px] h-[40px] md:py-[9px] md:px-[15px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                  >
                    キャンセル
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading || pdfLoading || excelLoading}
                    className={`w-[226px] md:w-[265px] lg:w-[358px] h-[40px] ${isLoading || pdfLoading || excelLoading
                      ? "bg-neutral-500"
                      : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                      } rounded-[4px] text-xs font-bold text-[#fff]`}
                  >
                    {isLoading || pdfLoading || excelLoading ? (
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    ) : (
                      "履歴書を出力する"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default OutputResume;

